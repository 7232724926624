module.exports = {
  siteTitle: 'Holicity Inc.', // <title>
  manifestName: 'Holicity',
  manifestShortName: 'Holicity', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix:'/gatsby-starter-aerial', 
  authorName: 'Holicity Inc.',
  authorHeading: '',
  authorSocialLinks: [
    {
     }, 
   ],
  };
